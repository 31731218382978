import styled from "styled-components"
import breakpoints from "./breakpoints"
import quotemark from "../css/backgrounds/quote-mark.svg"
import { regular16, bold16, bold20, bold28 } from "./typography"

const card = { width: "288px", margin: 8, height: "32.8rem" }
const cardSm = { width: "558px", margin: 12, height: "45.6rem" }
const cardMd = { width: "564px", margin: 16, height: "45.6rem" }
const cardLg = { width: "752px", margin: 24, height: "40rem" }

const marginLeft = index => {
  if (index === 0) {
    return `
      margin-left: calc((100vw - (${card.width} + (2 * ${card.margin}px))) / 2);

      @media screen and  (min-width: ${breakpoints.sm}px){
        margin-left: calc((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2);
      }
      
      @media screen and  (min-width: ${breakpoints.md}px){
        margin-left: calc((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2);
      }

      @media screen and  (min-width: ${breakpoints.l}px){
        margin-left: calc((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2);
      }
    `
  } else {
    return `

    margin-left: calc(((100vw - (${card.width} + (2 * ${card.margin}px))) / 2) - (${index} * (${card.width} + (2 * ${card.margin}px))));

    @media screen and  (min-width: ${breakpoints.sm}px){
      margin-left: calc(((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2) - (${index} * (${cardSm.width} + (2 * ${cardSm.margin}px))));
    }
    
    @media screen and  (min-width: ${breakpoints.md}px){
      margin-left: calc(((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2) - (${index} * (${cardMd.width} + (2 * ${cardMd.margin}px))));
    }

    @media screen and  (min-width: ${breakpoints.l}px){
      margin-left: calc(((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2) - (${index} * (${cardLg.width} + (2 * ${cardLg.margin}px))));
    }
    `
  }
}

const ClientCommentWrapper = styled.div`
  position: relative;
  width: 100%;
`
const ClientCommentList = styled.ul`
  ${props => marginLeft(props.index, props.totalQuotes)};
  transition: all 0.5s ease-in-out;
  ${props =>
    props.totalQuotes &&
    `width:calc(${props.totalQuotes}00vw + (16px*${props.totalQuotes * 2}));
      @media screen and (min-width: ${breakpoints.sm}px) {
       width:calc(${props.totalQuotes}00vw)
      }
      @media screen and  (min-width: 1304px){
        width:calc(${props.totalQuotes}*752px +  26.5vw + (${
      props.totalQuotes
    } * 75px));
      }
    `};
  list-style-type: none;
  display: flex;
  padding: 0;
  margin-top: 0;
  align-items: center;
  min-height: calc(51rem - (25px + 72px));
`

const ClientCommentListItem = styled.li`
  width: ${card.width};
  margin: 0 ${card.margin}px;
  opacity: ${props => (props.isInFocus ? `1` : `0.08`)};
  transition: opacity 0.35s ease-in-out;
  min-height: calc(51rem - (25px + 72px));
  @media screen and (min-width: ${breakpoints.sm}px) {
    width: ${cardSm.width};
    margin: 0 ${cardSm.margin}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${cardMd.width};
    margin: 0 ${cardMd.margin}px;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    width: ${cardLg.width};
    margin: 0 ${cardLg.margin}px;
  }

  blockquote {
    border-left: 0;
    padding: 0;
    display: flex;
    height: ${card.height};
    align-content: stretch;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;

    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 100%;
      padding: 4.8rem;
      height: ${cardSm.height};
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 5.6rem;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      height: ${cardLg.height};
    }

    &:before {
      width: 4rem;
      height: 3.4rem;
      top: -1.6rem;
      left: -0.8rem;
      position: absolute;
      content: "";
      display: block;
      visibility: visible;
      /* background-image: url(${quotemark}); */
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 4rem 3.4rem;
      opacity: ${props => (props.isInFocus ? `1` : `0`)};
      transition: opacity 1.2s ease-in-out;
      @media screen and (min-width: ${breakpoints.sm}px) {
        width: 7.2rem;
        height: 5.2rem;
        background-size: 7.2rem 5.2rem;
        top: 0;
        left: -1.8rem;
      }
    }

    p {
      ${regular16};
      // background: var(--white);
      border-left: 0;
      padding: 0 0 1.6rem;
      margin: 0;
      position: relative;
      text-align: center;
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold20};
      }

      @media screen and (min-width: ${breakpoints.l}px) {
        ${bold28};
      }
    }
    
    
    
  }

  cite {
    ${regular16};
    display: block;
    text-align: center;
    margin-top: 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 6rem;
    }
    
    b {
      display: block;
      ${bold16}
    }
  }
`

const ClientCommentArrows = styled.ul`
  display: none;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: flex;
    justify-content: space-between;
    z-index: 0;
    align-items: center;
    position: absolute;
    top: calc((${cardSm.height} / 2) - 41px);
    left: calc((100vw - (${cardSm.width} + 82px)) / 2);
    width: calc(${cardSm.width} + 82px);
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    top: calc((${cardMd.height} / 2) - 41px);
    left: calc((100vw - (${cardMd.width} + 82px)) / 2);
    width: calc(${cardMd.width} + 82px);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    top: calc((${cardLg.height} / 2) - 41px);
    left: calc((100vw - (${cardLg.width} + 82px)) / 2);
    width: calc(${cardLg.width} + 82px);
  }
`
const ClientCommentArrowItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 2;

  button {
    background: none;
    border: 0;
    outline: 0;
    position: relative;
    padding: 0;
    cursor: pointer;

    &:disabled {
      cursor: initial;
      svg {
        opacity: 0.3;
        transition: all 0.8s ease-in-out;
      }
    }
  }
`

const ClientCommentListDot = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 3;
  margin-top: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2rem;
  }
`

const ClientCommentListItemDot = styled.li`
  margin: 0 3px;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
  }

  input {
    width: 1.7rem;
    height: 1.7rem;
    appearance: none;
    border-radius: 50%;
    border: 0.3rem solid ${props => props.isInsideHero ? 'var(--white)' : 'var(--grey-mid)'};
    display: block;
    margin: 0.425rem;
    outline: none;
    cursor: pointer;

    &:checked {
      border: 0.3rem solid ${props => props.isInsideHero ? 'var(--white)' : 'var(--grey-mid-dark)'};
      background: ${props => props.isInsideHero ? 'var(--white)' : 'var(--grey-mid-dark)'};
    }
  }
`
export {
  ClientCommentWrapper,
  ClientCommentList,
  ClientCommentListItem,
  ClientCommentArrows,
  ClientCommentArrowItem,
  ClientCommentListDot,
  ClientCommentListItemDot,
}
